<template>
  <div>
    <modal :show="isModalShow" :showClose="false">
      <template slot="header">
        <h5 class="modal-title" v-if="confirmLeaveModal">{{ $t(`confirm.leavePage`) }}</h5>
        <h5 class="modal-title" v-else>
          {{isDeletingPicture ? $t("confirm.delete.image") : $t("confirm.delete.service")}}
        </h5>
      </template>
       <template v-if="confirmLeaveModal" slot="footer">
        <base-button type="danger" @click="leavePage(false)">
          {{$t("cancel")}}
        </base-button>
        <base-button type="secondary" @click="leavePage(true)">
          {{$t("confirm.continue")}}
        </base-button>
      </template>
      <template v-else slot="footer">
        <base-button type="secondary" @click="isModalShow = false; isDeletingPicture = false">
         {{$t("cancel")}}
        </base-button>
        <base-button v-if="isDeletingPicture" type="danger" @click="deletePicture($event)">
          {{ $t("delete") }}
          </base-button>
        </base-button>
        <base-button v-else type="danger" @click="deleteItem">
          {{$t("delete")}}
        </base-button>
      </template>
    </modal>

    <div class="row">
      <div class="col-12">
        <card>
          <template slot="header">
            <div class="col d-block pb-2 d-sm-flex justify-content-sm-between">
              <div>
                <base-button type="cancel" @click="goToList()">
                  {{ $t("goBack") }}
                </base-button>
              </div>
              <div>
                <base-button v-if="id != null && !isSpinnerShow" type="danger" class="mr-2" @click="showModalDelete()">
                  {{ $tc("delete") }}
                </base-button>
                <base-button :disabled="isBtnDisabled" native-type="submit" form="serviceForm" type="success">
                  {{ $t(isCreating? "create": "save") }}
                </base-button>
              </div>
            </div>
            <div class="col d-flex justify-content-between">
              <div>
                <h1 v-if="id == null" class="card-title mb-0">
                  {{ $t(`routes.servicesNew`) }}
                </h1>
                <h1 v-if="id != null" class="mb-0">
                  {{ service.name }}
                </h1>
              </div>
            </div>
          </template>

          <hr class="my-1" />

          <span v-if="employees.length === 0" class="d-block my-2 col">
            <svg class="mx-1" width="18px" height="18px" stroke-width="1.5" viewBox="0 0 24 24" fill="none"  color="#000000">
              <path d="M12 7v6M12 17.01l.01-.011M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z" stroke="#ff0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
            </svg>
            {{ $t('serviceNotDisplayed') }}
          </span>

          <form id="serviceForm" @change="disabledBtn(false)" @submit.prevent="manageItem()">
            <div v-if="!isSpinnerShow" class="col py-2">
              <div class="row">
                <div class="col-12 col-md-12 col-lg-4">
                  <base-input max="255" v-model="service.name" :placeholder="$t('name')" :label="`${$t('name')}*`" required></base-input>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <base-input inputType="number" inputmode="numeric" pattern="[0-9]*" step="0.01" min="0" v-model="service.price" :placeholder="$t('price')" :label="`${$t('price')}*`" required></base-input>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                  <base-input inputType="number" inputmode="numeric" pattern="[0-9]*" min="0" v-model="service.time" :placeholder="$t('time')" :label="`${$t('time')}*`" required></base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                  <div>
                    <label>{{ `${$tc("serviceCategory",2)}*` }}</label>
                  </div>
                  <Select
                    class="select-black w-100"
                    :placeholder="$tc('serviceCategory',2)"
                    v-model="service.category.id"
                    @change="disabledBtn(false)"
                  >
                    <Option
                      v-for="serviceCategory in serviceCategories"
                      class="select-default"
                      :value="serviceCategory.id"
                      :label="serviceCategory.name"
                      :key="serviceCategory.id"
                    >
                    </Option>
                  </Select>
                </div>
                <div class="col-12 col-md-6 col-lg-8 row pr-0 mt-4 mt-md-0">
                  <base-checkbox v-model="service.on_promotion" inline class="col-12 col-sm-4 col-md-6 m-0 pl-3">{{ $t("onPromotion") }}</base-checkbox>
                  <base-input inputType="number" inputmode="numeric" pattern="[0-9]*" min="0" max="100" v-model="service.discount" :disabled="!service.on_promotion" :placeholder="$t('discount')" :label="`${$t('discount')} %`" class="col-12 col-sm-8 col-md-6 m-0 pr-0 mt-2 pl-lg-4 mt-sm-0"></base-input>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <base-input inputType="number" inputmode="numeric" v-model="service.reminder_days" :placeholder="$t('days2remind')" :label="`${$t('days2remind')}`"></base-input>
                </div>
                <div class="col-12 mb-4">
                  <div>
                    <label class="control-label mt-4 mt-md-0">{{$tc("employee", 2)}}</label>
                  </div>
                  <Select
                      multiple
                      class="select-primary w-100"
                      :placeholder="$t('select.employee')"
                      v-model="selectedEmployees"
                      @change="disabledBtn(false)"
                  >
                    <Option
                        v-for="employee in allEmployees"
                        class="select-primary"
                        :value="employee.id"
                        :label="employee.name"
                        :key="employee.id"                        
                    >
                    </Option>
                  </Select>
                </div>
              </div>
              
              <div class="row mt-8">
                <div class="col-12 col-md-6 col-lg-4">
                  <label class="control-label mt-0 mt-md-0">
                    {{$t("picture")}}
                  </label>
                  <div>
                    <image-upload
                      id="inputMainPicture"
                      ref="inputMainPicture"
                      @change="onImageChange($event, 'mainPicture')"
                      :src="mainPicturePreview"
                      :changeText="$t('change')"
                      :removeText="$t('delete')"
                      :select-text="$t('select.picture')"
                      altImage="service"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-6 col-lg-8">
                  <label class="control-label mt-4 mt-md-0">
                    {{$t("gallery")}}
                  </label>
                  <div v-if="images.length > 0" class="galleryContainer">
                    <div v-for="(image, i) in images" :key="i" class="containerImage">
                      <img class="image pointer" :src="image" :alt="$t('gallery')" @click="onClick(i)" width="100%" height="100%"/>
                      <em class="deleteButton el-icon-delete pointer" @click="showModalDelete($event, true)"></em>
                    </div>
                    <vue-gallery-slideshow
                      :images="images"
                      :index="index"
                      @close="index = null"
                    ></vue-gallery-slideshow>
                  </div>
                  <image-upload-multiple
                    id="inputGallery"
                    ref="inputGallery"
                    @change="onImageChange($event, 'gallery')"
                    :src="images"
                    :isSaved="isSaved"
                  />
                  
                </div>
              </div>
           
              <div class="row mt-4">
                <div class="col-12">
                  <base-input :label="`${$t('description')}*`">
                    <textarea class="form-control" v-model="service.description" :placeholder="$t('description')" required></textarea>
                  </base-input>
                </div>
              </div>
            </div>
            <Spinner v-else></Spinner>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Card,
  BaseButton,
  BaseInput,
  BaseCheckbox,
  BaseTable,
  ImageUpload,
  ImageUploadMultiple,
  Modal,
  Spinner,
} from "@/components/index";
import { mapActions, mapState } from "vuex";
import VueGallerySlideshow from "vue-gallery-slideshow";
import { Select, Option } from "element-ui";

export default {
  name: "ServiceForm",
  data: () => {
    return {
      id: null,
      route: "/service",
      routeEmployees: "/employees",
      isBtnDisabled: true,
      isSaved: false,
      isBtnAddEmployeeDisable: true,
      service: {
        name: null,
        description: null,
        price: null,
        time: null,
        category: [],
        reminder_days: 0,
      },
      selectedEmployees:[],
      mainPicture: null,
      galleryPictures: null,
      serviceCategories: [],
      isModalShow: false,
      isDeletingPicture: false,      
      mainPicturePreview: null,
      images: [],
      index: null,
      pictureSelected: null,
      inputFiles: ["inputMainPicture", "inputGallery"],
      isSpinnerShow: true,
      employees: [],
      allEmployees: [],
      unassignedEmployees: [],
      employeeToAdd: null,
      idEmployee: null,
      confirmLeaveModal: false,
      isCreating: false,
    };
  },
  computed: {
    ...mapState(["serviceControl", "hasChangeForm"]),
  },
  methods: {
    createItem() {
      let service = new FormData();
      if (this.service.name) service.append("name", this.service.name);
      if (this.service.description) service.append("description", this.service.description);
      if (this.service.price) service.append("price", this.service.price);
      if (this.service.time) service.append("time", this.service.time);
      if (this.service.category.id) service.append("service_category_id", this.service.category.id);
      this.service.on_promotion
        ? service.append("on_promotion", 1)
        : service.append("on_promotion", 0);
      this.service.on_promotion
        ? service.append("discount", this.service.discount)
        : service.append("discount", 0);
      if (this.mainPicture) service.append("main", this.mainPicture);
      this.galleryPictures?.forEach((file) =>
        service.append("gallery[]", file)
      );
      if (this.service.reminder_days) service.append("reminder_days", this.service.reminder_days);
      this.selectedEmployees.forEach((item) => service.append("employees_service[]", item));
      return service;
    },
    manageItem() {
      const service = this.createItem();
      if (this.id != null) {
        this.updateItem(service);
        return;
      }
      this.storeItem(service);
    },
    onImageChange(file, type) {
      this.disabledBtn(false);
      if (type === "gallery") {
        if(this.isSaved) this.isSaved = false;
        const files = Object.values(file);
        this.galleryPictures = files;
        return;
      }
      this.mainPicture = file;
    },
    async storeItem(service) {
      this.disabledBtn(true);
      const response = await this.axios.post(this.route, service);
      if (response && response.data.status === "success") {
        this.$toast.success(this.$t("notifications.success.general"));
        
        this.$router.push(
          {
            name: this.isCreating ? 'servicesManage' : 'servicesList',
            params: this.isCreating? { id: response.data.data.services?.id } : {}
          }
        )
      }
    },
    async getItem() {
      const route = `${this.route}/${this.id}`;
      let response = await this.axios.get(route);
      this.selectedEmployees = [];
      if (response && response.data.status === "success") {
        const data = response.data.data.services;
        this.service = data;
        if(data.employees) this.employees = data.employees;
    
        if (data.pictures) {
          this.images = [];
          data.pictures.forEach((picture) => {
            picture.type === "2"
              ? this.images.push(picture.url)
              : (this.mainPicturePreview = picture.url);
          });
        }
        response.data.data.services.employees.forEach((item, index) => {
          this.selectedEmployees.push(item.id);
        });
        this.disabledBtn(true);
        this.isSpinnerShow = false;
      }
    },
    async getServiceCategories() {
      const route = `${this.route}-categories`;
      let response = await this.axios.get(route);
      if (response && response.data.status === "success") {
        const data = response.data.data.service_categories;
        this.serviceCategories = data;
      }
    },
    async getAllEmployees() {
      const route = `${this.routeEmployees}`;
      let response = await this.axios.get(route);

      if (response && response.data.status === "success") {
        const data = response.data.data.employees.data;
        this.allEmployees = data;
        this.filterUnassignedEmployees()
      }
    },
    filterUnassignedEmployees() {
      this.unassignedEmployees = [];
      this.employeeToAdd = null;
      this.allEmployees.forEach(allEmployee => {
        if (!this.employees.some(employee => employee.id === allEmployee.id)) {
          this.unassignedEmployees.push(allEmployee);
        }
      })
    },
    async updateItem(service) {
      this.disabledBtn(true);
      service.append("_method", "PUT");
      const route = `${this.route}/${this.id}`;
      let response = await this.axios.post(route, service);
      if (response && response.data.status === "success") {
        this.getItem();
        this.$toast.success(this.$t("notifications.success.general"));
        this.clearInputFiles();
        this.isSaved = true;
      }
    },
    async deleteItem() {
      this.disabledBtn(true);
      const route = `${this.route}/${this.id}`;
      let response = await this.axios.delete(route);

      this.isModalShow = false;
      if (response && response.data.status === "success") {
        this.$router.push({name: 'servicesList'})
        this.$toast.success(this.$t("notifications.success.general"));
      }
    },
    disabledBtn(disabled) {
      this.isBtnDisabled = disabled;
      this.$store.dispatch('setHasChangeForm', !disabled )
    },
    showModalDelete(e = null, deletePicture = false) {
      this.isModalShow = true;
      this.isDeletingPicture = deletePicture;
      if (deletePicture) {
        this.pictureSelected = e.target.previousSibling.currentSrc;
      }
    },
    async deletePicture() {
      const pictureToDelete = this.service.pictures.find(
        (picture) => picture.url === this.pictureSelected
      );
      const route = `${this.route}/${this.id}/picture/${pictureToDelete.id}`;

      let response = await this.axios.delete(route);
      if (response && response.data.status === "success") {
        this.$toast.success(this.$t("notifications.success.pictureDeleted"));
        this.isModalShow = false;
        this.isDeletingPicture = false;
        this.getItem();
      }
    },
    onClick(i) {
      this.index = i;
    },
    clearInputFiles() {
      this.inputFiles.forEach( inputFile => {
        if(!this.$refs || !inputFile) return;

        this.$refs[inputFile]?.cleanInput()
      });
      this.galleryPictures = [];      
    },
    goToList(){
      this.$router.push({name: 'servicesList'})
    },
    ...mapActions(["setHasChangeForm"])
  },
  async beforeMount() {
    this.id = this.$route.params.id;

    if (this.id != null) {
      this.isCreating = false;
      await this.getItem();
    } else {
      this.isCreating = true;
      this.isSpinnerShow = false;
    }
    
    this.getAllEmployees();
    this.getServiceCategories();
  },
  components: {
    Card,
    BaseButton,
    BaseInput,
    BaseTable,
    BaseCheckbox,
    ImageUpload,
    ImageUploadMultiple,
    Modal,
    VueGallerySlideshow,
    Select,
    Option,
    Spinner,
  },
};
</script>

<style scoped>
.image {
  width: 120px;
  height: 120px;
  background-size: contain;
  margin: 10px;
  border-radius: 3px;
}
.pointer {
  cursor: pointer;
}
.deleteButton {
  align-items: center;
  border-radius: 0.5rem;
  color: red;
  display: flex;
  font-size: 17px;
  font-weight: bold;
  height: 2rem;
  justify-content: center;
  margin: 0 auto 15px;
  width: 2rem;
}
.deleteButton:hover {
  background: rgba(255 0 0 / 50%);
  color: white;
}
.containerImage {
  display: inline-block;
}
.galleryContainer {
  min-height: 223px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.m-auto {
  display: block;
  margin: 0 auto;
}
.deleteBtn {
  padding: 11px 15px;
}
.addBtn {
  padding: 11px 10px;
}
@media (min-width: 576px) {
  .galleryContainer {
    justify-content: start;
  }
  .addBtn, .deleteBtn {
    padding: 11px 40px;
  }
}
@media (min-width: 768px) {
  .addBtn {
    max-width: 150px;
  }
}
</style>
